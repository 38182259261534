// IMPORTS
import { Dimensions } from "react-native";
// SCREEN
const screen = {
  height: Dimensions.get("screen").height,
  width: Dimensions.get("screen").width,
};
// GRID
const grid = {
  length: screen.width / 20 < 17 ? Math.floor(screen.width / 20) : 17,
  cell: 20,
};
// COLORS
const colors = {
  white: "#ffffff",
  whiteFade: "rgba(255, 255, 255, 0.5)",
  black: "#000000",
  gray: "#888888",
  red: "#ff0800",
  green: "#59982f",
  yellow: "#f7e697",
  purple: "#a482ff",
  phone: "#1d1d1d",
  phoneLense: "#1f3a61",
  phoneSpeaker: "#161616",
  phoneBar: "#505050",
};
// CONTROLS
const controls = {
  up: require("../assets/controls/up.png"),
  down: require("../assets/controls/down.png"),
  left: require("../assets/controls/left.png"),
  right: require("../assets/controls/right.png"),
  play: require("../assets/controls/play.png"),
  pause: require("../assets/controls/pause.png"),
  repeat: require("../assets/controls/repeat.png"),
};
// EXPORTS
export { screen, grid, colors, controls };
