// IMPORTS
import React, { Component } from "react";
import { View } from "react-native";
import { grid } from "../libraries/variables";
import { styles } from "../styles/appStyles";
// COMPONENT
export default class Tail extends Component {
  constructor(props) {
    super(props);
  }
  // GENERATE TAIL
  generateTail() {
    const size = this.props.size;
    const elements = this.props.elements;
    return elements.map((element, index) => {
      return (
        <View
          key={index}
          style={[
            styles.tail,
            {
              width: size,
              height: size,
              top: element.y * size,
              left: element.x * size,
            },
          ]}
        />
      );
    });
  }
  // RENDER
  render() {
    const size = this.props.size;
    return (
      <View
        style={{
          width: grid.length * size,
          height: grid.length * size,
        }}
      >
        {this.generateTail()}
      </View>
    );
  }
}
