// IMPORTS
import { StyleSheet } from "react-native";
import { screen, grid, colors } from "../libraries/variables";
// VARIABLES
const shortScreen = screen.height <= 700;
const narrowScreen = screen.width <= 320;
// STYLES
const styles = StyleSheet.create({
  // APP
  appContainer: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
    backgroundColor: colors.purple,
  },
  // GAME
  gameContainer: {
    height: grid.length * grid.cell + 10,
    width: narrowScreen ? "100%" : grid.length * grid.cell + 10,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.black,
  },
  gameEngine: {
    height: grid.length * grid.cell,
    width: grid.length * grid.cell,
    backgroundColor: colors.yellow,
    flex: null,
  },
  gameTitle: {
    position: "absolute",
    color: colors.black,
    fontSize: 50,
  },
  // CONTROLS
  controlsContainer: {
    height: shortScreen ? 225 : 300,
    width: shortScreen ? 225 : 300,
    alignItems: "center",
    justifyContent: shortScreen ? "space-around" : "space-between",
  },
  controlRow: {
    height: shortScreen ? 50 : 75,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  controlButton: {
    height: shortScreen ? 50 : 75,
    width: shortScreen ? 50 : 75,
    alignItems: "center",
    justifyContent: "center",
  },
  controlButtonImage: {
    height: "100%",
    width: "100%",
  },
  // FOOD
  food: {
    position: "absolute",
    backgroundColor: colors.red,
  },
  // HEAD
  head: {
    position: "absolute",
    backgroundColor: colors.green,
  },
  // TAIL
  tail: {
    position: "absolute",
    backgroundColor: colors.green,
  },
});
// EXPORT
export { styles };
