// IMPORTS
import React, { Component } from "react";
import { View } from "react-native";
import { styles } from "../styles/appStyles";
// COMPONENT
export default class Head extends Component {
  constructor(props) {
    super(props);
  }
  // RENDER
  render() {
    const x = this.props.position.x;
    const y = this.props.position.y;
    const size = this.props.size;
    return (
      <View
        style={[
          styles.head,
          {
            width: size,
            height: size,
            top: y * size,
            left: x * size,
          },
        ]}
      />
    );
  }
}
