// IMPORTS
import { grid } from "./variables";
// RANDOM POSITION
const randomPosition = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
// GAME LOOP
const gameLoop = (entities, { dispatch, events }) => {
  // STATE
  let head = entities.head;
  let food = entities.food;
  let tail = entities.tail;
  // HANDLE EVENTS
  if (events.length) {
    for (let i = 0; i < events.length; i++) {
      if (events[i].type === "move-down" && head.speed.y != -1) {
        head.speed.y = 1;
        head.speed.x = 0;
      } else if (events[i].type === "move-up" && head.speed.y != 1) {
        head.speed.y = -1;
        head.speed.x = 0;
      } else if (events[i].type === "move-left" && head.speed.x != 1) {
        head.speed.y = 0;
        head.speed.x = -1;
      } else if (events[i].type === "move-right" && head.speed.x != -1) {
        head.speed.y = 0;
        head.speed.x = 1;
      }
    }
  }
  // HEAD LOGIN
  head.nextMove -= 1;
  if (head.nextMove === 0) {
    head.nextMove = head.updateFrequency;
    // COLLISION WITH WALL
    if (
      head.position.x + head.speed.x < 0 ||
      head.position.x + head.speed.x >= grid.length ||
      head.position.y + head.speed.y < 0 ||
      head.position.y + head.speed.y >= grid.length
    ) {
      dispatch({ type: "game-over" });
    } else {
      // NEW TAIL
      let newTail = [{ x: head.position.x, y: head.position.y }];
      tail.elements = newTail.concat(tail.elements).slice(0, -1);
      // MOVE SNAKE
      head.position.x += head.speed.x;
      head.position.y += head.speed.y;
      // COLLISION WITH TAIL
      for (let i = 0; i < tail.elements.length; i++) {
        if (
          tail.elements[i].x === head.position.x &&
          tail.elements[i].y === head.position.y
        ) {
          dispatch({ type: "game-over" });
        }
      }
      // COLLISION WITH FOOD
      if (
        head.position.x === food.position.x &&
        head.position.y === food.position.y
      ) {
        // ADD FOOD TO TAIL
        tail.elements = [{ x: food.position.x, y: food.position.y }].concat(
          tail.elements
        );
        // SPAWN NEW FOOD
        food.position.x = randomPosition(0, grid.length - 1);
        food.position.y = randomPosition(0, grid.length - 1);
      }
    }
  }
  return entities;
};
// EXPORTS
export { randomPosition, gameLoop };
