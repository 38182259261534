// IMPORTS
import React, { Component } from "react";
import { StatusBar, View, Text, Image, TouchableOpacity } from "react-native";
import { registerRootComponent } from "expo";
import { GameEngine } from "react-native-game-engine";
import { styles } from "./styles/appStyles";
import { randomPosition, gameLoop } from "./libraries/methods";
import { grid, controls } from "./libraries/variables";
import Food from "./components/Food";
import Head from "./components/Head";
import Tail from "./components/Tail";
// COMPONENT
export default class App extends Component {
  constructor(props) {
    super(props);
    this.boardSize = grid.length * grid.cell;
    this.engine = null;
    this.state = {
      running: false,
      gameOver: false,
    };
  }
  // GAME TEMPLATE
  gameTemplate() {
    return {
      food: {
        position: {
          x: randomPosition(0, grid.length - 1),
          y: randomPosition(0, grid.length - 1),
        },
        size: 20,
        renderer: <Food />,
      },
      head: {
        position: { x: 0, y: 0 },
        speed: { x: 1, y: 0 },
        nextMove: 10,
        updateFrequency: 10,
        size: 20,
        renderer: <Head />,
      },
      tail: { size: 20, elements: [], renderer: <Tail /> },
    };
  }
  // CONTROL BUTTON
  controlButton = ({ value, method }) => {
    return (
      <TouchableOpacity style={styles.controlButton} onPress={() => method()}>
        <Image source={value} style={styles.controlButtonImage} />
      </TouchableOpacity>
    );
  };
  // RENDER
  render() {
    return (
      <View style={styles.appContainer}>
        {/* GAME */}
        <View style={styles.gameContainer}>
          <GameEngine
            ref={(ref) => (this.engine = ref)}
            style={styles.gameEngine}
            systems={[gameLoop]}
            entities={this.gameTemplate()}
            running={this.state.running}
            onEvent={(event) => {
              if (event.type === "game-over") {
                this.setState({
                  running: false,
                  gameOver: true,
                });
              }
            }}
          >
            <StatusBar hidden={true} />
          </GameEngine>
          {this.state.gameOver && (
            <Text style={styles.gameTitle}>Game Over</Text>
          )}
        </View>
        {/* CONTROLS */}
        <View style={styles.controlsContainer}>
          {/* TOP ROW */}
          <View style={styles.controlRow}>
            {this.controlButton({
              value: controls.up,
              method: () => this.engine.dispatch({ type: "move-up" }),
            })}
          </View>
          {/* MIDDLE ROW */}
          <View
            style={[styles.controlRow, { justifyContent: "space-between" }]}
          >
            {this.controlButton({
              value: controls.left,
              method: () => this.engine.dispatch({ type: "move-left" }),
            })}
            {this.controlButton({
              value: this.state.gameOver
                ? controls.repeat
                : this.state.running
                ? controls.pause
                : controls.play,
              method: () => {
                if (this.state.gameOver) {
                  this.engine.swap(this.gameTemplate());
                  this.setState({
                    running: true,
                    gameOver: false,
                  });
                } else {
                  this.setState({ running: !this.state.running });
                }
              },
            })}
            {this.controlButton({
              value: controls.right,
              method: () => this.engine.dispatch({ type: "move-right" }),
            })}
          </View>
          {/* BOTTOM ROW */}
          <View style={styles.controlRow}>
            {this.controlButton({
              value: controls.down,
              method: () => this.engine.dispatch({ type: "move-down" }),
            })}
          </View>
        </View>
      </View>
    );
  }
}
// INITIALIZE
registerRootComponent(App);
